<template>
  <div>
    <div class="table-responsive table-finalise">
      <table class="table table-row-gray-300 gy-7">
        <thead>
        <tr class="fw-bolder border-bottom fs-8 text-gray-700">
          <td>{{ vueTranslator('common.description') }}</td>
          <td :colspan="showChargeVat ? 2 : 1">{{ vueTranslator('common.unit_price') }}<span v-if="showChargeVat"> (incl. BTW)</span></td>
          <td>{{ vueTranslator('common.unit.label') }}</td>
          <td>{{ vueTranslator('common.amount_number') }}</td>
          <td>{{ vueTranslator('common.total') }}</td>
          <td></td>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(row, index) in rows" :class="{'border-bottom': index === rows.length - 1}">
          <td style="vertical-align: bottom;">
              <input type="hidden"
                     :value="row.id"
                     :name="getName(index, 'id')">
            <Multiselect
                v-if="options.length"
                :id="'multiselect-cost-type-' + index"
                class="form-control form-control-lg form-control-solid"
                v-model="row.id"
                :disabled="false"
                @change="typeChange($event, row)"
                :options="getTypes()"
                @click="toggleMultiselect(index)"
            />
            <input v-if="options.length"
                   type="hidden"
                   :value="row.description"
                   :name="getName(index, 'cost')">
            <input v-if="!options.length" class="form-control form-control-lg form-control-solid"
                   data-lpignore="true"
                   style="width: 233px"
                   v-model="row.description"
                   required
                   type="text"
                   @change="otherCostChange($event, row)"
                   :name="getName(index, 'cost')">
          </td>
          <td>
            <div class="textfield-with-front-icon">
              <span v-if="options.length" v-text="`€ ${row.price}`"></span>
              <i v-if="!options.length">€ </i>
              <input class="form-control form-control-lg form-control-solid"
                     data-lpignore="true"
                     v-model="row.price"
                     required
                     :type="options.length > 0 ? 'hidden' : 'number'"
                     :name="getName(index, 'unitPrice')"
                     step=".01">
            </div>
          </td>
          <td v-if="showChargeVat" style="vertical-align: bottom;">
            <input type="hidden"
                   :value="row.vat_charged"
                   :name="getName(index, 'vatCharged')">
            <Multiselect
                :id="'multiselect-cost-vat-' + type + '-' + index"
                class="form-control form-control-lg form-control-solid"
                style="width: 120px!important;"
                :options="getVatChoices()"
                :value="0"
                @click="toggleVatMultiselect(index)"
                v-model="row.vat_charged"
            />
          </td>
          <td>
            <span v-if="options.length" v-text="row.unit"></span>
            <input class="form-control form-control-lg form-control-solid"
                   data-lpignore="true"
                   v-model="row.unit"
                   required
                   :type="options.length > 0 ? 'hidden' : 'text'"
                   :name="getName(index, 'unit')">
          </td>
          <td>
            <input class="form-control form-control-lg form-control-solid"
                   data-lpignore="true"
                   v-model="row.amount"
                   required
                   type="number"
                   :name="getName(index, 'amount')"
                   step=".01"
                   :style="showChargeVat ? 'width: 80px;' : ''">
          </td>
          <td style="width: 100px">
              <i>€ </i>
              <span>{{ Math.round(((row.price * row.amount) + Number.EPSILON) * 100) / 100 }}</span>
          </td>
          <td>
            <i @click="removeRow(index)"
               class="bi bi-dash-circle-fill cursor-pointer"></i>
          </td>
        </tr>
        <tr>
          <td colspan="4"></td>
          <td colspan="2">
            <h4>
              <i>€ </i>
              {{ sumOfRows() }}
            </h4>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="mt-3">
      <button type="button" @click="addRow" class="btn btn-sm btn-add-row cursor-pointer">
        <i class="fa fa-plus"></i>
        {{ vueTranslator('common.add_prices_cost') }}
      </button>
    </div>
  </div>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import { vueTranslator } from '../mixins.js';

export default {
  props: [
    'baseName', 'optionsJson', 'costsData', 'type', 'chargeVat'
  ],
  components: {Multiselect},
  data() {
    return {
      options: this.optionsJson ? JSON.parse(this.optionsJson) : [],
      originalCosts: this.costsData ? JSON.parse(this.costsData) : [],
      defaultRow: {
        id: '',
        description: '',
        price: 0,
        unit: '',
        amount: 0,
        total: 0
      },
      rows: [],
      vueTranslator,
      vatChoices: [0, 6, 12, 21],
      showChargeVat: this.chargeVat
    };
  },
  methods: {
    addRow() {
      this.rows.push({...this.defaultRow})
    },
    removeRow(index) {
      if (this.rows.length >= 1) {
        this.rows.splice(index, 1);
      }
    },
    getName(index, key) {
      return `${this.baseName}[${index}][${key}]`
    },
    sumOfRows() {
      const sum = this.rows.reduce((partialSum, row) => partialSum + (row.amount * row.price), 0)
      return Math.round((sum + Number.EPSILON) * 100) / 100
    },
    getTypes() {
      return this.options.map(option => {
        return {
          value: option.id,
          label: option.cost
        }
      })
    },
    getVatChoices() {
      return this.vatChoices.map(choice => {
        return {
          value: choice,
          label: choice + '% BTW'
        }
      })
    },
    toggleMultiselect(id) {
      const dropdown = document.getElementById(`multiselect-cost-type-${id}`).querySelector(`#multiselect-cost-type-${id}-dropdown`);
      dropdown.classList.toggle('is-hidden');
    },
    typeChange(event, row) {
      if (!event) {
        return;
      }

      const option = this.options.find((option) => option.id === event);
      row.price = option.unitPrice
      row.unit = option.unit
      row.id = option.id
      row.description = option.cost
      row.vat_charged = option.vatCharged
    },
    otherCostChange(event, row) {
      row.id = row.description;
    },
    toggleVatMultiselect(id) {
      let type = this.type;
      const dropdown = document.getElementById(`multiselect-cost-vat-${type}-${id}`)
          .querySelector(`#multiselect-cost-vat-${type}-${id}-dropdown`);

      dropdown.classList.toggle('is-hidden');
    },
  },
  mounted() {
    const self = this;

    const $chargeVatEl = document.getElementById(`chargeVat`);
    $chargeVatEl.addEventListener('change', function() {
      self.showChargeVat = !self.showChargeVat;

      if (false === self.showChargeVat) {
        self.rows.forEach((row) => {
          row.vat_charged = '';
        })
      }
    });

    if (this.originalCosts.length > 0) {
      this.rows.push(...this.originalCosts);

      return;
    }
  }
};
</script>

<style></style>

